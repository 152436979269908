import TaxCalculation from "./components/TaxCalculation";

function App() {
  return (
    <>
      <TaxCalculation />
    </>
  );
}

export default App;
